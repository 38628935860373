var trs_app = {};

// Array to store regions used to fill select
trs_app.regions = [];

// Array to strore data for all regions/themes to avoid multiple requests
trs_app.regionData = [];

// Array to store data for the region chart
trs_app.regionChartData = [];

// Object to manage national and regional theme data
trs_app.themesData = {
    countNational: 0,
    countRegional: 0,
    themes: {} ,
    init: function(themes) {
        this.countNational = 0;
        this.countRegional = 0;
        this.themes = {};

        for (idx = 0; idx < themes.length; idx++) {
            this.themes[themes[idx].theme] = {
                national: 0,
                regional: 0
            };
        }
    },
    getThemes: function() {
        return Object.keys(this.themes);
    },
    setNationalData: function(nationalData) {
        for (idx = 0; idx < nationalData.length; idx++) {
            this.themes[nationalData[idx].theme].national = parseInt(nationalData[idx].value);
            this.countNational += parseInt(nationalData[idx].value);
        }
    },
    getNationalData: function() {
        var nationalData = [];

        $.each(this.themes, function(index, value) {
            nationalData.push(value.national);
        });

        return nationalData;
    },
    getNationalDataAsPercentages: function() {
        var nationalData = [];

        var that = this;
        $.each(this.themes, function(index, value) {
            nationalData.push(((value.national / that.countNational) * 100).toFixed(0));
        });

        return nationalData;
    },
    setRegionalData: function(regionalData, selectedRegion) {
        this.clearRegionalData();

        var that = this;
        $.each(regionalData, function(index, currentRegion) {
            if (currentRegion.region === selectedRegion){
                that.themes[currentRegion.theme].regional = parseInt(currentRegion.value);
                that.countRegional += parseInt(currentRegion.value);
            }
        });
    },
    getRegionalData: function() {
        var regionalData = [];

        $.each(this.themes, function(index, value) {
            regionalData.push(value.regional);
        });

        return regionalData;
    },
    getRegionalDataAsPercentages: function() {
        var regionalData = [];

        var that = this;
        $.each(this.themes, function(index, value) {
            var result = (value.regional / that.countRegional);
            if (isNaN(result)) {
                result = 0;
            }
            regionalData.push((result * 100).toFixed(0));
        });

        return regionalData;
    },
    clearRegionalData: function() {
        this.countRegional = 0;

        $.each(this.themes, function(index, value) {
            value.regional = 0;
        });
    }
};
// Object to manage national and regional question data
trs_app.questionsData = {
    countNational: {},
    countRegional: {},
    questions: {} ,
    successKey: "",
    init: function(questions) { 
        this.countNational = {};
        this.countRegional = {};
        this.questions = {};
        this.successKey="";

        for (idx = 0; idx < questions.length; idx++) {
            this.questions[questions[idx].value] = {
                national: 0,
                regional: 0,
            };
            this.countNational[questions[idx].value] = 0;
            this.countRegional[questions[idx].value] = 0;
             
        }

    },
    

    getQuestions: function() {
        return Object.keys(this.questions);
    },
    setNationalData: function(nationalData) {
        for (idx = 0; idx < nationalData.length; idx++) {
            this.questions[nationalData[idx].question].national = parseInt(nationalData[idx].value);
            this.countNational[nationalData[idx].question] = parseInt(nationalData[idx].workshops); 
        }
    },
    getNationalData: function() {
        var nationalData = [];

        $.each(this.questions, function(index, value) {
            nationalData.push(value.national);
        });

        return nationalData;
    },
    getNationalDataAsPercentages: function() {
        var nationalData = [];

        var that = this;
        $.each(this.questions, function(index, value) {
            nationalData.push(((value.national / that.countNational[index]) * 100).toFixed(0));
        });
        

        return nationalData;
    },
    setRegionalData: function(regionalData, selectedRegion) {
        this.clearRegionalData();

        var that = this;
        $.each(regionalData, function(index, currentRegion) {
            if (currentRegion.region === selectedRegion){
                that.questions[currentRegion.question].regional = parseInt(currentRegion.value);
                that.countRegional[currentRegion.question] = parseInt(currentRegion.workshops);
            }
        });
    },
    getRegionalData: function() {
        var regionalData = [];

        $.each(this.questions, function(index, value) {
            regionalData.push(value.regional);
        });

        return regionalData;
    },
    getRegionalDataAsPercentages: function() {
        var regionalData = [];

        var that = this;
        $.each(this.questions, function(index, value) {
            var result = (value.regional / that.countRegional[index]);
            if (isNaN(result)) {
                result = 0;
            }
            regionalData.push((result * 100).toFixed(0));
        });

        return regionalData;
    },
    clearRegionalData: function() {
        $.each(this.countRegional, function(index, value) {
            value = 0;
        });

        $.each(this.questions, function(index, value) {
            value.regional = 0;
        });
    }
};

trs_app.regionChart = null;

// Unregister global pugin
Chart.plugins.unregister(ChartDataLabels);

Chart.defaults.global.defaultFontFamily = "Ubuntu";

// Function to populate region select
trs_app.populateSelectRegions = function() {
    $('#regionSelect').append('<option value="">Veuillez sélectionner une région</option>');
    $.each(trs_app.regions, function( key, value ) {
        $('#regionSelect').append('<option value="' + value + '">' + value + '</option>');
    });
};

// Functions to retrieve statistics data
trs_app.fetchGlobalData = function (callback){
    $.ajax({
        url: '/statistiques/atelier3/global',
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        data: {"successKey":trs_app.questionsData.successKey },
        success: function (data) {
            trs_app.questionsData.setNationalData(data);

            callback();
        },
        error: function (data, l, error) {
            console.log(error);
        }
    });
};

trs_app.fetchRegionData = function(contextRegion){
    $.ajax({
        url: '/statistiques/atelier3/region',
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        data: {"successKey": trs_app.questionsData.successKey },
        success: function (data) {
            //console.log('region');
            //console.log(data);
            trs_app.regionData = data;
            trs_app.createRegionChart(contextRegion);
        },
        error: function (data, l, error) {
            console.log(error);
        }
    });
};

// Function to create gobal chart
trs_app.createGlobalChart = function(ctxGlobal) {

    var globalChart = new Chart(ctxGlobal, {
        type: 'doughnut',
        data: {
            labels: trs_app.themesData.getThemes(),
            datasets: [{
                label: 'Global',
                data: trs_app.themesData.getNationalData(),
                backgroundColor: [
                    'rgba(254, 179, 147)',
                    'rgba(120, 201, 184)',
                    'rgba(0, 145, 168)',
                    'rgba(172, 8, 57)',
                    'rgba(253, 111, 80)',
                ],
            }]
        },
        options: {
            elements: {
                arc: {
                    borderWidth: 0,
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    fontSize: 14,
                }
            }
        }
    });
};

// Functions to create & update region chart
trs_app.createRegionChart = function(ctxRegion) {

    trs_app.regionChartData = [
        {
            label: 'National',
            backgroundColor: '#FF0000',
            //data: trs_app.themesData.getNationalDataAsPercentages(),
            data: trs_app.questionsData.getNationalDataAsPercentages(),
            datalabels: {
                formatter: function(value, ctx) {
                    return value + "%";
                },
                color: '#FF0000',
            },
        }
    ];

    trs_app.regionChart = new Chart(ctxRegion, {
        type: 'horizontalBar',
        data: {
            //labels: trs_app.themesData.getThemes(),
            labels: trs_app.questionsData.getQuestions(),
            datasets: trs_app.regionChartData
        },
        plugins: [ChartDataLabels],
        options: {
            layout: {
                padding: {
                    left: 0,
                    right: 60,
                    top: 0,
                    bottom: 0
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontSize: 15,
                        fontStyle: 'bold',
                    },
                    gridLines: {
                        display: false
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        display: false
                    },
                }]
            },
            legend: {
                position: 'bottom',
                labels: {
                    fontSize: 14,
                }
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    clamp: true,
                    font: {
                        size: 20,
                        weight: 700,
                    },
                }
            }
        }
    });
};

trs_app.updateRegionChart = function(region) {

    //trs_app.themesData.setRegionalData(trs_app.regionData, region);
    trs_app.questionsData.setRegionalData(trs_app.regionData, region);

    var newRegionData = {
        label: region,
        backgroundColor: '#400543',
        //data: trs_app.themesData.getRegionalDataAsPercentages(),
        data: trs_app.questionsData.getRegionalDataAsPercentages(),
        datalabels: {
            formatter: function(value, ctx) {
                return value + "%";
            },
            color: '#400543'
        },
    };

    // Clear previous region data in chart if existing
    if(trs_app.regionChartData.length > 1){
        trs_app.regionChartData.pop();
    }

    // Push new region data and update chart
    trs_app.regionChartData.push(newRegionData);
    trs_app.regionChart.update();
};

trs_app.getNetworksBySelectedRegion = function(jsonData, regionValue) {
    // Enabling network select
    if($('#contribution_network').attr('disabled') == "disabled") {
        $("#contribution_network").removeAttr('disabled');
    }

    // Getting networks from json and by region value
    var networksFound = jsonData.filter(function(data){ return data.region == regionValue; });

    // No networks found => 'Veuillez selectionner' value got selected so disabling select
    if(networksFound[0] && networksFound[0].networks){
        // Emptying and populating network select
        $('#contribution_network').empty();
        networksFound[0].networks.forEach(function(element) {
            $('#contribution_network').append($('<option>', {
                value: element,
                text: element
            }));
        });
    } else {
        $('#contribution_network').attr('disabled','disabled');
    }
};

trs_app.hideMessageBisTextarea = function (isConfirmation) {
    var messageBisContainer = !isConfirmation ? $("#contribution_messageBis") : $("#messageBisConfirmation");

    if(messageBisContainer.css('opacity') !== "0"){
        messageBisContainer.css('opacity', 0);
        messageBisContainer.css('position', "absolute");

        var messageBisLabel = !isConfirmation ? $("label[for='contribution_messageBis']") : $("#messageBisConfirmationLabel > label");
        messageBisLabel.css('opacity', 0);
        messageBisLabel.css('position', "absolute");
    }

    if(isConfirmation){

    }
};

trs_app.createMessageBisFromInput = function (userInput){
    var messageBisValue = JSON.stringify(userInput);
    messageBisValue = messageBisValue
        .replaceAll('},"', ';')
        .replaceAll('":{"', '/')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('"', '');

    $('#contribution_messageBis').val(messageBisValue);
};

trs_app.updateMessageBisValue = function (userInput, questionsContainer){
    questionsContainer.change(function () {
        var question = $(this).data('question');
        var response = $(this).data('response');
        userInput[question][response] = this.checked ? 1 : 0;

        trs_app.createMessageBisFromInput(userInput);
    });
};


trs_app.parseMessageBis = function (isConfirmation) {
    var messageBisContainer = isConfirmation ? $("#messageBisConfirmation") : $("#contribution_messageBis");
    var jsonStringValue = "{" + messageBisContainer.val() + "}}";
    jsonStringValue = jsonStringValue
        .replaceAll(/[a-zA-Z]\d/ig, '"$&"')
        .replaceAll(/[a-zA-Z]:/ig, '"$&')
        .replaceAll(/:[0-1]/ig, '"$&')
        .replaceAll(';', '},')
        .replaceAll('/', ':{');

    var jsonValue = JSON.parse(jsonStringValue);

    Object.entries(jsonValue).map(function (question) {
        Object.entries(question[1]).map(function (response) {
            $('input[data-question="' + question[0] + '"][data-response="' + response[0] + '"]').prop('checked', !!response[1]);
        });
    });

    return jsonValue;
};

trs_app.showWorkshopQuestions = function(questions, isConfirmation) {
    var userInput = {};
    var workshopNumber = window.location.pathname.split("atelier").pop();

    if(isConfirmation){
        var profile = $('#messageBisConfirmation').data('workshop');
        workshopNumber = profile.slice(-1);
    }

    questions.filter(function(question) {
        return question.workshop && question.workshop === 'atelier' + workshopNumber;
    }).map(function(workshopQuestion) {
        trs_app.hideMessageBisTextarea(isConfirmation);
        var questionCode = workshopQuestion.value;
        userInput[questionCode] = {};

        $('.form .row:last').before('' +
            '<div id="' + questionCode + '" class="row mb-3">' +
            '<div class="col-12 col-md-4 text-md-right">' +
            '<label>' + workshopQuestion.label + '</label>' +
            '</div>' +
            '<div id="' + questionCode + 'Responses" class="col-12 col-md-8 question">'
        );

        workshopQuestion.responses.map(function(response) {
            var responseCode = response.value;
            userInput[questionCode][responseCode] = 0;

            $('#' + questionCode + 'Responses').append(
                '<input ' + (isConfirmation ? 'disabled' : '') + ' ' +
                'data-question="' + questionCode + '" ' +
                'data-response="' + responseCode + '" ' +
                'type="checkbox" ' +
                'name="'  + questionCode + responseCode + '" >' +
                '&nbsp;' +
                '<span>' + response.label + '</span>' +
                '<br>'
            );
        });

        if(!window.location.pathname.includes("modification") && !isConfirmation) {
            trs_app.createMessageBisFromInput(userInput);
        }

        $('#' + questionCode).append('</div></div>');
    });

    if(window.location.pathname.includes("modification") || isConfirmation){
        userInput = trs_app.parseMessageBis(isConfirmation);
    }

    var questionsContainer = $('.question input[type=checkbox]');
    if(questionsContainer.length){
        trs_app.updateMessageBisValue(userInput, questionsContainer);
    }
};

// jQuery events
$(document).ready(function() {

    // // Check we are on statistics page
    // if ($('#statistics').length) {
    //     var contextGlobal = null;
    //     var contextRegion= null;
    //
    //     $.getJSON("/assets/data/regions.json", function(dataRegions) {
    //         trs_app.regions = dataRegions.regions;
    //         trs_app.populateSelectRegions();
    //     });
    //
    //     //$.getJSON("/assets/data/themes.json", function(dataThemes) {
    //         $.getJSON("/assets/data/questions.json", function(dataQuestions) {
    //         // Init theme data object
    //         //trs_app.themesData.init(dataThemes.themes);
    //         trs_app.questionsData.init(dataQuestions);
    //         //for (idx = 0; idx < dataQuestions.length; idx++) {
    //             trs_app.questionsData.successKey= dataQuestions[0].value+"/"+dataQuestions[0].success+";"+dataQuestions[1].value+"/"+dataQuestions[1].success+";"+dataQuestions[2].value+"/"+dataQuestions[2].success+";"+dataQuestions[3].value+"/"+dataQuestions[3].success+";"+dataQuestions[4].value+"/"+dataQuestions[4].success+";"+dataQuestions[5].value+"/"+dataQuestions[5].success;
    //         //}
    //
    //
    //         // Draw charts
    //         /*var globalCharlElt = document.getElementById('globalChart');
    //         if (globalCharlElt) {
    //             contextGlobal = globalCharlElt.getContext('2d');
    //         }*/
    //
    //         var regionChartElt = document.getElementById('regionChart');
    //         if (regionChartElt) {
    //             contextRegion = regionChartElt.getContext('2d');
    //         }
    //
    //         if (contextRegion){
    //             trs_app.fetchGlobalData(function() {
    //                 trs_app.fetchRegionData(contextRegion);
    //             });
    //         }
    //     });
    // }
    //
    // $('#regionSelect').on('change', function (e) {
    //     if ($('#regionSelect').val() === "") {
    //         // Clear regional chart region data if no region is selected
    //         if (trs_app.regionChartData.length > 1) {
    //             trs_app.regionChartData.pop();
    //             trs_app.regionChart.update();
    //         }
    //     } else {
    //         // Update regional chart for selected region
    //         trs_app.updateRegionChart($('#regionSelect').val());
    //     }
    // });

    $('.description__item__toggler').click(function(){
        var parentElt = $(this).parents('.description__item');
        var headerElt = parentElt.children('.description__item__header');
        var detailElt = parentElt.children('.description__item__detail');

        $(this).toggleClass('description__item__toggler--expanded');
        headerElt.toggleClass('description__item__header--expanded');

        if (detailElt.is(':visible')) {
            detailElt.slideUp();
        } else {
            detailElt.slideDown();
        }
    });

    // if (window.location.pathname.includes("atelier") || ($('#messageBisConfirmation').length && $('#messageBisConfirmation').val().includes("Q1/A:"))) {
    //     $.getJSON("/assets/data/questions.json", function(questions) {
    //         trs_app.showWorkshopQuestions(questions, $('#messageBisConfirmation').length !== 0);
    //     });
    // }


    if($('#contribution_network').length){
        $.getJSON("/assets/data/regions_networks.json", function(json) {
            var jsonData = json.data;

            // "Veuillez selectionner" value selected for region
            if($('#contribution_region').val() === '') {
                $('#contribution_network').attr('disabled','disabled');
            } else {
                // Actual value selected for region on page init (user is correcting form)
                // Getting former value for network because we will overwrite that value by populating networks select
                var selectedNetwork = $('#contribution_network').val();
                trs_app.getNetworksBySelectedRegion(jsonData, $('#contribution_region').val());
                // Re-selecting former selected value
                $("#contribution_network").val(selectedNetwork);
            }

            // Changing values when user selects region
            $('#contribution_region').on('change', function (e) {
                trs_app.getNetworksBySelectedRegion(jsonData, e.target.value);
            });
        });
    }

    var validationButton = $('#validation-button');

    if(validationButton.length){
        validationButton.on('click', function (e) {
            validationButton.css('display', 'none');
            $('#correct-button').css('display', 'none');
            $('#after-validation-buttons').removeClass('d-none');
        });
    }

});
